<template>
  <div id="app">
    <Log />
    <Navbar />

    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Log from "./components/Log.vue";

export default {
  name: "app",
  components: {
    Log,
    Navbar,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "montserrat", sans-serif;
}

header {
  background-color: #0176ba;
  min-height: 56px;
}
</style>
