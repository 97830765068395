<template>
  <nav>
    <div class="OneContainer">
      <router-link to="/main">
        <img
          class="logo"
          height="100"
          width="300"
          src="https://kommunassurans.se/wp-content/uploads/2023/11/Logotype-Kommunassurans-Forsakring-300x100-1.png"
        />
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "log",
};
</script>
<style>
nav {
  justify-content: end;
}

.menu-items {
  color: black;
  padding: 10px 20px;
  position: relative;
  text-align: right;
  border-bottom: 3px solid transparent;
}

.menu-items a {
  color: black;
  text-decoration: underline;
  margin-top: 40px;
}

img {
  display: flex;
  padding-top: 20px;
  width: 230px;
  height: 95px;
}

.OneContainer {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 116px;
  padding-left: var(--bs-gutter-x, 0.8rem);
}
#app {
  max-width: 100%;
}
</style>
