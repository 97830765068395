<template>
  <div class="dashboard">
    <Sidebar />

    <div class="content">
      <b-container>
        <div class="title">Optionslistor</div>
        <b-row class="dropAttributes">
          <div>
            <label for="category" class="color">Välj kategori</label>
            <select
              v-model="selected"
              id="category"
              @change="onChange($event.target.value)"
            >
              <option
                v-for="item in categories"
                v-bind:key="item.key"
                v-bind:value="item.key"
              >
                {{ item.label }}
              </option>
            </select>
          </div>
        </b-row>
        <b-row v-if="displayContent">
          <div id="value-table">
            <table class="table table-striped table-hover table.bordered">
              <thead>
                <tr class="header" scope="col">
                  <th scope="col">Kod</th>
                  <th scope="col">Beskrivning</th>
                  <th class="text-right"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="attribute in attributes"
                  v-bind:key="attribute.key"
                  v-bind:class="{ deactivated: !attribute.active }"
                >
                  <td>{{ attribute.value }}</td>
                  <td>{{ attribute.description }}</td>
                  <td class="text-right">
                    <button
                      class="btn btn-secondary badge"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Redigera"
                      :disabled="!attribute.active"
                      @click="openEditModal(attribute.id)"
                    >
                      <i class="fas fa-solid fa-pen"></i>
                    </button>
                    <button
                      class="btn btn-primary badge"
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="attribute.active ? 'Inaktivera' : 'Aktivera'"
                      @click="toggleActive(attribute.id)"
                    >
                      <i
                        class="fas fa-solid"
                        v-bind:class="{
                          'fa-ban': attribute.active,
                          'fa-check': !attribute.active,
                        }"
                      ></i>
                    </button>
                    <button
                      @click="deleteAttribute(attribute.id)"
                      class="btn btn-danger badge"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Radera"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-row>
        <b-row v-if="displayContent" class="mt-1">
          <div>
            <button
              class="btn btn-primary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#add-value-collapse"
              aria-expanded="false"
              aria-controls="add-value-collapse"
            >
              Nytt värde
            </button>
            <div class="collapse" id="add-value-collapse">
              <div class="card card-bodys">
                <form @submit.prevent="submitForm">
                  <div class="form-group">
                    <input
                      type="text"
                      class="Form-controls"
                      id="value"
                      placeholder="Kod"
                      v-model="attributeCode"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="Form-controls"
                      id="code"
                      placeholder="Beskrivning"
                      v-model="attributeLabel"
                    />
                  </div>
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="!allInputs"
                    >
                      Lägg till
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>
  </div>
  <div class="modal" v-if="showModal">
    <div>hej</div>
  </div>
  <edit-attribute-modal
    v-if="showModal"
    @save-event="saveModal"
    @cancel-event="hideModal"
    :description="editDescription"
  />
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import { axiosBase } from "@/axios-api";
import EditAttributeModal from "@/components/EditAttributeModal.vue";
import { useToast } from "vue-toastification";

export default {
  name: "Attributes",
  components: {
    Sidebar,
    EditAttributeModal,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      categories: [],
      attributes: [],
      attributeCode: "",
      attributeLabel: "",
      selected: null,
      editDescription: "",
      editId: 0,
      showModal: false,
    };
  },
  computed: {
    displayContent: function () {
      return !!this.selected;
    },
    allInputs: function () {
      return !!(this.attributeCode && this.attributeLabel);
    },
  },
  created() {
    this.getCategories();
  },
  methods: {
    openEditModal(id) {
      this.editId = id;
      this.editDescription = this.attributes.find(
        (x) => x.id === id
      ).description;
      this.showModal = true;
    },
    saveModal(event) {
      this.hideModal();
      this.updateDescription(this.editId, event.description);
    },
    hideModal() {
      this.showModal = false;
    },
    async submitForm() {
      const attribute = {
        field_category: this.selected,
        value: this.attributeCode,
        description: this.attributeLabel,
      };
      await axiosBase
        .post("api/v1/attributes/", attribute)
        .then(() => {
          // TODO pusha in i array för att slippa hämta allt igen? hur göra med order då?
          this.toast.success(
            "Värdet " +
              attribute.value +
              " - " +
              attribute.description +
              " tillagt"
          );
          this.attributeCode = "";
          this.attributeLabel = "";
        })
        .catch((error) => this.handleError(error));
      await this.onChange(this.selected);
    },
    async getCategories() {
      await axiosBase
        .get("api/v1/attributes/get_distinct_fields/")
        .then((response) => {
          this.categories.push(...response.data);
        })
        .catch((error) => this.handleError(error));
    },
    async onChange(event) {
      await axiosBase
        .get("api/v1/attributes/?field_category=" + event)
        .then((response) => {
          this.attributes = response.data;
        })
        .catch((error) => this.handleError(error));
    },
    async updateDescription(id, description) {
      await axiosBase
        .patch(`api/v1/attributes/${id}/`, { description: description })
        .then(() => {
          this.toast.success("Beskrivningen har uppdaterats");
        })
        .catch((error) => this.handleError(error));
      await this.onChange(this.selected);
    },
    async toggleActive(id) {
      const item = this.attributes.find((x) => x.id === id);
      await axiosBase
        .patch(`api/v1/attributes/${id}/`, { active: !item.active })
        .then(() => {
          // TODO pusha in i array för att slippa hämta allt igen? hur göra med order då?
          const text = item.active
            ? "Värdet har inaktiverats"
            : "Värdet har aktiverats";
          this.toast.success(text);
        })
        .catch((error) => this.handleError(error));
      await this.onChange(this.selected);
    },
    async deleteAttribute(id) {
      const item = this.attributes.find((x) => x.id === id);
      await axiosBase
        .delete(`api/v1/attributes/${id}/`)
        .then(() => {
          // TODO pusha in i array för att slippa hämta allt igen? hur göra med order då?
          this.toast.success(
            "Värdet " + item.value + " - " + item.description + " raderat"
          );
        })
        .catch((error) => this.handleError(error));
      await this.onChange(this.selected);
    },
    handleError(error) {
      if (error.response) {
        // Request made and server responded
        let message = "";
        for (const property in error.response.data) {
          error.response.data[property].forEach(function (item) {
            message += item;
          });
        }
        this.toast.error(message);
      } else if (error.request) {
        // The request was made but no response was received
        this.toast.error("Something went wrong");
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .dashboard {
    height: 104vh;
  }
  select#dropdownSelect.form-select {
    padding-left: 8px;
    margin-top: 50px;
  }
  div.row.dropAttributes {
    width: 63%;
    margin-left: 7px !important;
  }
}

@media (min-width: 1400px) {
  #dropdownSelect {
    margin-left: 15px;
  }
  .dropAttributes {
    max-width: 67%;
    padding-right: 10px;
  }
  .dashboard {
    height: 82vh;
  }
}
#dropdownSelect {
  margin-top: 4rem;
  padding-left: 8px;
}

.header {
  position: sticky;
  z-index: 3;
  color: white;
  top: -2px;
  font-size: 15px;
}
#attributesLägg {
  margin-right: 110px !important;
}
#läggTill {
  margin-top: 10px;
}
.Dropdown {
  margin-top: 86px;
  margin-left: 2rem;
}

.dropdown-menu {
  margin-top: 1rem !important;
}

.dropdown-menu,
.dropdown-menu-dark {
  padding-left: 2px !important;
}

#attributes {
  margin-left: 3rem !important;
}

label {
  padding-bottom: 5px;
}

.hanteraOptionslistor {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 30px;
  color: rgb(167, 167, 167);
  font-weight: 600;
}

.card {
  padding-left: 0.7rem !important;
}
div#value-table {
  overflow: auto;
  max-height: 550px;
}
.form-group {
  margin-bottom: 0 !important;
  margin-top: 14px;
}

.deactivated {
  text-decoration: line-through;
}
</style>
