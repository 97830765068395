import axios from "axios";
import store from "./store";

const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  //baseURL: "http://127.0.0.1:8000",
  timeout: 12000,
});

// before any API call make sure that the access token is good
axiosBase.interceptors.request.use(async (config) => {
  await store.dispatch("isLoggedIn");
  return config;
});

export { axiosBase };
