<script>
/* eslint-disable */
export default {
  props: {
    field: Object,
    fieldname: String,
    help: Boolean,
    placeholder: String,
  },
  emits: ["updatedValue", "focus", "blur", "keypress"]
};
</script>
<template>
  <div v-bind:class="{ required: !!field.required }">
    <label :for="fieldname" class="form-label">{{ field.label }}</label>
    <button
      v-if="help || !!field.help"
      id="bklInfo"
      class="btn btn-link btn-icon btn-sm"
      @click="$emit('help')"
    >
      <i class="fas fa-info-circle"></i>
    </button>
    <input
      v-model="field.value"
      :type="field.type"
      :maxlength="!!field.length ? field.length : ''"
      v-bind:class="{ 
        'form-control': true,
        'is-invalid': !!field.invalid 
      }"
      :id="fieldname"
      :style="field.styleObject"
      :placeholder="placeholder ? placeholder : field.placeholder"
      :disabled="field.disable || field.locked"
      :required="field.required"
      @focus="$emit('focus', $event.target.value)"
      @blur="$emit('blur', $event.target.value)"
      @keypress="$emit('keypress', $event)"
      @change="$emit('updatedValue', $event.target.value)"
    />
  </div>
</template>

<style scoped>
.required label:after {
  content: "*";
  color: red;
}

.form-label {
  font-weight: 600;
  color: #0176ba;
}
</style>