<script>
/* eslint-disable */
export default {
  props: {
    field: Object,
    fieldname: String,
    posts: Array,
    subTitle: String,
    rows: {
      type: Number,
      default: 2
    }
  },
  emits: ["updatedValue"]
};
</script>
<template>
  <label :for="fieldname" class="form-label">{{ field.label }}</label>
  <div
    v-for="post in posts"
    v-bind:key="post.id"
    class="posting"
  >
    <i class="posting"
      >[{{ post.created_by.username }} {{ post.created_on }}]</i
    >
    <p class="posting">{{ post.text }}</p>
  </div>
  <div class="posting bold">{{ subTitle }}</div>
  <textarea
    v-model="field.value"
    id="specific_terms"
    :style="field.styleObject"
    :rows="rows"
    @change="$emit('updatedValue', $event.target.value)"
  >
  </textarea>
</template>

<style scoped>
.posting {
  width: 100%;
}
textarea {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.bold {
  font-weight: bold;
}
.required label:after {
  content: "*";
  color: red;
}

.form-label {
  font-weight: 600;
  color: #0176ba;
}
</style>