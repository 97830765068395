<script>
/* eslint-disable */
export default {
  props: ["field", "values", "fieldname", "help"],
  emits: ["updatedValue", "help"],
  updated() {
    if(!this.field.value) {
      const init = this.values.find((x) => x.default);
      if (init) this.field.value = init.value;
    }
  }
};
</script>
<template>
  <div v-bind:class="{ required: !!field.required }">
    <label :for="fieldname" class="color">{{ field.label }}</label>
    <button
      v-if="help || field.help"
      id="bklInfo"
      class="btn btn-link btn-icon btn-sm"
      @click="$emit('help')"
    >
      <i class="fas fa-info-circle"></i>
    </button>
    <select
      v-model="field.value"
      :id="fieldname"
      :style="field.styleObject"
      :disabled="field.disable || field.locked"
      :required="field.required"
      @change="$emit('updatedValue', $event.target.value)"
      v-bind:class="{ 
        'form-control': true,
        'is-invalid': !!field.invalid 
      }"
    >
      <option
        class="buttonOne"
        v-for="item in values"
        :key="item.value"
        :value="item.value"
        :disabled="item.active !== undefined && !item.active"
      >
        {{ item.text }}
      </option>
    </select>
  </div>
</template>
<style scoped>
label {
  font-weight: 600;
  color: #0176ba;
}
.required label:after {
  content: "*";
  color: red;
}
</style>