export const insuranceObjectFields = {
  id: {
    type: "number",
    disable: true,
  },
  insurance_company: {
    type: "text",
    disable: true,
    value: "Kommunassurans Försäkring AB",
    placeholder: "Kommunassurans Försäkring AB",
  },
  municipality: {
    required: true,
    type: "select",
  },
  property_owner: {
    required_nonadmin: true,
    warning_admin: true,
    type: "text",
    length: 100,
  },
  management: {
    type: "text",
    length: 40,
  },
  internal_municipality_id: {
    type: "text",
    length: 40,
  },
  name: {
    type: "text",
    required: true,
    length: 120,
  },
  property_designation: {
    warning_admin: true,
    required_nonadmin: true,
    type: "text",
    length: 100,
  },
  street_name: {
    warning_admin: true,
    required_nonadmin: true,
    type: "text",
    length: 100,
  },
  street_number: {
    warning_admin: true,
    required_nonadmin: true,
    type: "text",
    length: 20,
  },
  zip_code: {
    warning_admin: true,
    required_nonadmin: true,
    type: "number",
    length: 5,
  },
  city: {
    warning_admin: true,
    required_nonadmin: true,
    type: "text",
    length: 255,
  },
  coordinate_lat: {
    required_nonadmin: true,
    type: "number",
  },
  coordinate_lon: {
    required_nonadmin: true,
    type: "number",
  },
  group: {
    type: "text",
    length: 255,
  },
  official_id: {
    type: "text",
    length: 25,
  },
  external_id: {
    warning_nonadmin: true,
    type: "text",
    length: 25,
  },
  electrical_id: {
    type: "text",
    length: 40,
  },
  category_external: {
    required_nonadmin: true,
    warning_admin: true,
    type: "select",
  },
  category_internal: {
    type: "select",
  },
  specification: {
    required_nonadmin: true,
    warning_admin: true,
    type: "text",
    length: 255,
  },
  calculated_value: {
    required_nonadmin: true,
    type: "text",
    currency: true,
  },
  calculation_method: {
    required_nonadmin: true,
    type: "text",
    length: 40,
  },
  calculation_date: {
    required_nonadmin: true,
    type: "date",
    length: 40,
  },
  calculated_value_content: {
    type: "text",
    currency: true,
  },
  calculated_value_total: {
    type: "text",
    currency: true,
  },
  form_of_insurance: {
    warning_admin: true,
    disable_nonadmin: true,
    type: "select",
  },
  insurance_amount: {
    disable_nonadmin: true,
    type: "text",
    length: 40,
    currency: true,
  },
  first_risk: {
    disable_nonadmin: true,
    type: "number",
    currency: true,
  },
  created_on: {
    disable: true,
    placeholder: new Date().toLocaleDateString("sv-SE"),
    type: "text",
  },
  created_by: {
    disable: true,
    type: "text",
  },
  requested_insurance_from_date: {
    required_nonadmin: true,
    type: "date",
    placeholder: "Tillsvidare",
  },
  insurance_from_date: {
    warning_admin: true,
    disable_nonadmin: true,
    type: "date",
    placeholder: "Ej försäkrad",
  },
  uw: {
    required_admin: true,
    type: "text",
    length: 255,
  },
  requested_insurance_to_date: {
    type: "date",
  },
  insurance_to_date: {
    disable_nonadmin: true,
    type: "date",
  },
  adjusted_premium: {
    disable_nonadmin: true,
    type: "date",
    currency: true,
  },
  tenant: {
    type: "text",
    length: 40,
  },
  build_year: {
    warning_admin: true,
    required_nonadmin: true,
    type: "number",
    length: 4,
  },
  latest_renovation: {
    type: "number",
    length: 4,
  },
  cultural_protection_type: {
    warning_admin: true,
    required_nonadmin: true,
    type: "text",
    length: 255,
  },
  facility_class: {
    required_nonadmin: true,
    type: "select",
  },
  gross_floor_area: {
    warning_admin: true,
    required_nonadmin: true,
    type: "text",
  },
  basement_included: {
    type: "select",
  },
  fire_alarm: {
    required_nonadmin: true,
    type: "select",
  },
  burglar_alarm: {
    required_nonadmin: true,
    type: "select",
  },
  burglar_alarm_type: {
    type: "text",
    disable: true,
  },
  fire_protection: {
    required_nonadmin: true,
    type: "select",
  },
  external_note: {
    type: "textfield",
  },
  internal_note: {
    type: "textfield",
  },
  premium: {
    type: "text",
  },
  insurance_company_information: {
    type: "text",
  },
  specific_terms: {
    disable_nonadmin: true,
    type: "text",
  },
  fire_alarm_type: {
    type: "text",
  },
  other_responsible: {
    type: "text",
  },
  other_responsible_email: {
    type: "email",
  },
  solar_cells: {
    required_nonadmin: true,
    type: "select",
  },
  energy_storage: {
    required_nonadmin: true,
    type: "text",
  },
  number_of_floors: {
    warning_admin: true,
    type: "number",
  },
};
