<template>
  <div class="box">
    <div class="boxOne">
      <br />

      <div class="row" id="'LoginForm">
        <legend class="col-md-6" id="ChangePassword">Aktivera användare</legend>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { axiosBase } from "@/axios-api";

export default {
  name: "ActivatePassword.vue",
  setup() {
    const toast = useToast();
    return { toast };
  },
  mounted() {
    axiosBase
      .get(
        `auth/users/activate/${this.$route.params.uid}/${this.$route.params.token}/`
      )
      .then(async (response) => {
        console.log(response.data);
        this.toast.success("Användaren har blivit aktiverad!");
        await this.$router.push({ name: "Login" });
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          let message = "";
          for (const property in error.response.data) {
            error.response.data[property].forEach(function (item) {
              message += item;
            });
          }
          this.toast.error(message);
        } else if (error.request) {
          // The request was made but no response was received
          this.toast.error("Something went wrong");
          console.log(error.request);
        }
      });
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .box {
    margin-left: 450px;
    width: 50%;
    margin-top: 20px;
  }
}

@media (min-width: 1400px) {
  .box {
    margin-left: 40%;
    width: 35%;
    margin-top: 100px;
  }
}

.btn.btn-primary {
  margin-left: 2px !important;
}

#resetPassword {
  box-shadow: rgb(0 0 0 / 6%) 1px 1px 9px 1px;
  padding: 30px 20px;
}

#emailBox {
  padding-left: 0;
}
</style>
