export const insuranceObjectTemplate = {
  municipality: "",
  management: "",
  internal_municipality_id: "",
  name: "",
  property_designation: "",
  city: "",
  category_external: "",
  first_risk: "",
  insurance_company: "",
  property_owner: "",
  street_name: "",
  street_number: "",
  zip_code: "",
  coordinate_lat: "",
  coordinate_lon: "",
  group: "",
  official_id: "",
  external_id: "",
  electrical_id: "",
  specification: "",
  calculated_value: "",
  calculation_method: "",
  calculation_date: null,
  calculated_value_content: "",
  calculated_value_total: "",
  requested_insurance_from_date: null,
  uw: "",
  requested_insurance_to_date: null,
  tenant: "",
  build_year: "",
  latest_renovation: "",
  monument: "",
  cultural_protection_type: "",
  facility_class: "",
  gross_floor_area: "",
  basement_included: "",
  fire_alarm: "",
  burglar_alarm_exists: "",
  sprinkler: "",
  external_note: "",
  form_of_insurance: "",
  insurance_amount: "",
  insurance_from_date: null,
  insurance_to_date: null,
  changemaster: "",
  created_by: "",
  created_on: "",
  adjusted_premium: null,
  internal_note: "",
  category_internal: "",
  premium: "",
  specific_terms: "",
  insurance_company_information: "",
};
