<template>
  <header>
    <div>
      <router-link
        v-if="canEdit"
        type="button"
        class="floated"
        :to="{ name: 'Create' }"
        >Skapa nytt
      </router-link>

      <router-link
        v-if="user.isAdmin === 'true'"
        type="button"
        class="floated"
        :to="{ name: 'Admin' }"
        >Admin
        <span
          v-if="active_changes_count.count > 0"
          class="icon-button__badge"
          >{{ active_changes_count.count }}</span
        >
      </router-link>
      <router-link
        v-if="user.group === 'INTERMEDIARY'"
        type="button"
        class="floated"
        :to="{ name: 'Inbox' }"
        >Inkorg
        <span
          v-if="active_changes_count.count > 0"
          class="icon-button__badge"
          >{{ active_changes_count.count }}</span
        >
      </router-link>
      <router-link
        v-if="!loggedIn"
        type="button"
        class="floated"
        :to="{ name: 'Login' }"
        >Login</router-link
      >
      <button v-if="loggedIn" @click="logout()" class="floated">
        <u>Log out</u>
      </button>
      <strong
        v-if="fullname"
        class="welcome"
        style="color: #212529"
        @click="changePassword()"
        ><span>Välkommen {{ fullname }}</span></strong
      >
    </div>
  </header>
</template>

<script>
import { axiosBase } from "@/axios-api";

export default {
  name: "navbar",
  data() {
    return {
      active_changes_count: {},
      user: {},
      loggedIn: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/") {
        this.logout();
        this.getUser();
      } else if (from.path === "/") {
        this.getUser();
        this.getActiveChangesCount();
      } else if (to.path !== "/") {
        this.getActiveChangesCount();
      }
    },
  },
  computed: {
    fullname: function () {
      const firstName = this.user.first_name ?? "";
      const lastName = this.user.last_name ?? "";
      return firstName + " " + lastName;
    },
    isIntermediary: function () {
      return this.user.group === "INTERMEDIARY";
    },
    canEdit: function () {
      return this.user.canEdit;
    },
  },
  beforeMount() {},
  methods: {
    changePassword() {
      this.$router.push({ name: "Password" });
    },
    getUser() {
      // Retrieve user information and if user is logged in
      this.user = this.$store.getters.userDetail;
      this.loggedIn = this.$store.getters.isLoggedIn;
    },
    async getActiveChangesCount() {
      let changemaster = "";
      if (this.user.isAdmin === "true") {
        changemaster = "?status=at_admin";
      } else if (this.user.group === "INTERMEDIARY") {
        changemaster = "?status=at_intermediary";
      }
      await axiosBase
        .get("api/v1/changemaster/count/" + changemaster)
        .then((response) => {
          this.active_changes_count = response.data;
        })
        .catch(() => {});
    },
    async logout() {
      await this.$store.dispatch("logout");
      await this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style>
.welcome {
  display: flex;
  justify-content: flex-end;
  color: white !important;
  padding-top: 15px;
  margin-right: 30px;
  font-weight: 200 !important;
  cursor: pointer;
}
.welcome:hover span {
  display: none;
}
.welcome:hover:before {
  content: "Ändra Lösenord";
}
.col-md-6 {
  margin-bottom: 1.5rem;
}
.header {
  background-color: #0176ba;
  min-height: 56px;
  margin-top: 50px;
}

.floated {
  float: left;
  padding: 6px 20px;
  margin-top: 0.5rem;
  background-color: white;
  color: #0176ba;
  margin-left: 8px;
  border: none;
  border-radius: 2px;
  box-shadow: white;
}

.floated:hover {
  background-color: #0176ba;
  color: white;
  border: solid 1px;
}

.icon-button:hover {
  cursor: pointer;
}

.icon-button:active {
  background-color: #cccccc;
}

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: #333333;
  background-color: #dddddd;
  border: none;
  outline: none;
  border-radius: 50%;
}

.icon {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
.icon-button__badge {
  margin-top: -24px;
  background-color: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  border-radius: 80%;
  margin-left: 47px;
  width: 40%;
}
</style>
