<template>
  <div>
    <h1>{{ title }}</h1>
    <p style="color: red">
      <b>{{ changeText }}</b>
    </p>
  </div>
  <div class="page-container">
    <b-row>
      <b-col col cols="8">
        <div class="scrollable">
          <div class="list-group list-group-fullwidth">
            <div
              class="list-group-item"
              v-for="(object, key) in item"
              :id="key"
              v-bind:key="key"
            >
              <strong
                v-if="
                  key !== 'internal_note' &&
                  key !== 'external_note' &&
                  key !== 'specific_terms' &&
                  key !== 'insurance_company_information'
                "
                class="color"
                >{{ object.label }}
              </strong>
              <div
                v-if="
                  key !== 'internal_note' &&
                  key !== 'external_note' &&
                  key !== 'specific_terms' &&
                  key !== 'insurance_company_information'
                "
              >
                {{ object.value }}
              </div>
            </div>
            <div class="list-group-item">
              <strong class="color"
                >{{ returnFieldLabel("external_note") }}
              </strong>
              <div>
                <textarea
                  class="text-box"
                  v-model="external_note"
                  aria-rowspan="5"
                  disabled="true"
                >
                </textarea>
              </div>
            </div>
            <div v-if="internalUser" class="list-group-item">
              <strong class="color"
                >{{ returnFieldLabel("internal_note") }}
              </strong>
              <div>
                <textarea
                  class="text-box"
                  v-model="internal_note"
                  aria-rowspan="5"
                  disabled="true"
                >
                </textarea>
              </div>
            </div>
            <div class="list-group-item">
              <strong class="color">{{
                returnFieldLabel("specific_terms")
              }}</strong>
              <div
                v-for="post in specific_terms"
                v-bind:key="post.id"
                class="posting"
              >
                <i class="posting"
                  >[{{ post.created_by.username }} {{ post.created_on }}]</i
                >
                <p class="posting">{{ post.text }}</p>
              </div>
            </div>
            <div class="list-group-item">
              <strong class="color">{{
                returnFieldLabel("insurance_company_information")
              }}</strong>
              <div
                v-for="post in insurance_company_information"
                v-bind:key="post.id"
              >
                <i class="posting"
                  >[{{ post.created_by.username }} {{ post.created_on }}]</i
                >
                <p class="posting">{{ post.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="left-menu-container">
          <div class="list-group list-menu">
            <a
              v-if="canEdit"
              id="andtaObjekt"
              class="list-group-item list-group-item-action"
            >
              <router-link :to="{ name: 'Edit' }">
                <i class="fas fa-exchange-alt"></i>
                Ändra Objekt
              </router-link>
            </a>

            <a
              @click="toggleChanges()"
              class="list-group-item list-group-item-action"
            >
              <i class="fas fa-database"></i>
              Visa ändringar
            </a>
            <a
              class="list-group-item list-group-item-action"
              @click="getExport('csv')"
            >
              <i class="fas fa-file-export"></i> Exportera CSV
            </a>
            <a
              class="list-group-item list-group-item-action"
              @click="getExport('pdf')"
            >
              <i class="fas fa-file-export"></i> Exportera PDF
            </a>
          </div>
          <div class="smallMap">
            <maptest v-bind:coord_in="coord" @lat="lat = $event"></maptest>
          </div>
        </div>
      </b-col>
    </b-row>
    <section
      class="sidebar-wrapper"
      v-bind:class="displayChanges ? 'overlay' : ''"
    >
      <div class="sidebar">
        <div class="sidebar-header">
          <button v-on:click="toggleChanges" class="close">
            <span>&times;</span>
          </button>
        </div>
        <h2 class="changes">Historiska Ändringar</h2>
        <div class="column is-6">
          <table class="table is-full">
            <thead>
              <tr>
                <th>Fält</th>
                <th>Från</th>
                <th>Till</th>
                <th>När</th>
                <th>Av</th>
                <th>Godkänd</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="change in changes" v-bind:key="change.id">
                <td>{{ change.field }}</td>
                <td>{{ change.old_value }}</td>
                <td>{{ change.new_value }}</td>
                <td>{{ change.changed_on }}</td>
                <td>{{ change.changed_by }}</td>
                <td>{{ change.pending }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { axiosBase } from "@/axios-api";
import maptest from "@/components/maptest";

export default {
  data() {
    return {
      user: this.$store.getters.userDetail,
      pendingChanges: false,
      displayChanges: false,
      name: "",
      property_designation: "",
      internal_note: "",
      external_note: "",
      specific_terms: [],
      insurance_company_information: [],
      lat: "gaga",
      item: {},
      changes: [],
      columns: [],
      coord: {
        lat: 0,
        lon: 0,
      },
      fKeys: ["municipality"],
      attributes: [
        "category_external",
        "category_internal",
        "facility_class",
        "fire_alarm",
        "solar_cells",
        "form_of_insurance",
        "cultural_protection_type",
        "fire_protection",
        "burglar_alarm",
      ],
      nonDislayFields: [
        "changes",
        "changemaster",
        "internal_note",
        "external_note",
        "specific_terms",
        "insurance_company_information",
      ],
      currencies: [
        "calculated_value",
        "insurance_amount",
        "premium",
        "calculated_value_content",
        "calculated_value_total",
        "first_risk",
      ],
    };
  },
  components: {
    maptest,
  },
  computed: {
    canEdit: function () {
      return this.user.canEdit === "true";
    },
    hasChanges: function () {
      return this.pendingChanges;
    },
    title: function () {
      if (this.name) {
        return this.name;
      } else if (this.property_designation) {
        return this.property_designation;
      } else {
        return "";
      }
    },
    changeText: function () {
      if (this.pendingChanges) {
        return "har en pågående ändringsbegäran";
      } else {
        return "";
      }
    },
    internalUser: function () {
      return this.user.group === "INTERN";
    },
  },
  methods: {
    toggleChanges() {
      this.displayChanges = !this.displayChanges;
    },
    returnFieldLabel: function (key) {
      // eslint-disable-next-line no-undef
      const item = this.columns.find((x) => x.key === key);
      return item ? item.label : "";
    },
    setInsuranceDates: function () {
      if (
        !this.item.insurance_from_date.value &&
        !this.item.insurance_to_date.value
      ) {
        this.item.insurance_from_date.value = "Ej försäkrad";
        this.item.insurance_to_date.value = "Ej försäkrad";
      } else if (
        this.item.insurance_from_date.value &&
        !this.item.insurance_to_date.value
      ) {
        this.item.insurance_to_date.value = "Tillsvidare";
      }
    },
    async getItem() {
      await axiosBase
        .get(`api/v1/insuranceobject/${this.$route.params.id}/`)
        .then((response) => {
          this.external_note = response.data["external_note"];
          this.internal_note = response.data["internal_note"];
          this.specific_terms = response.data["specific_terms"];
          this.insurance_company_information =
            response.data["insurance_company_information"];
          let key = "";

          for (let i = 0; i < this.columns.length; i++) {
            key = this.columns[i].key;
            if (this.$helpers.inDict(key, response.data)) {
              if (this.fKeys.some((x) => x === key)) {
                if (response.data[key]) {
                  this.item[key] = {
                    value: response.data[key].name.concat(
                      " - Org nr: ",
                      response.data[key].org_no
                    ),
                    label: this.columns[i].label,
                  };
                } else {
                  this.item[key] = {
                    value: response.data[key],
                    label: this.columns[i].label,
                  };
                }
              } else if (this.attributes.some((x) => x === key)) {
                if (response.data[key]) {
                  this.item[key] = {
                    value: response.data[key].description,
                    label: this.columns[i].label,
                  };
                } else {
                  this.item[key] = {
                    value: response.data[key],
                    label: this.columns[i].label,
                  };
                }
              } else if (this.currencies.some((x) => x === key)) {
                this.item[key] = {
                  value: this.$helpers.returnCurrency(response.data[key]),
                  label: this.columns[i].label,
                };
              } else {
                this.item[key] = {
                  value:
                    key === "created_by"
                      ? response.data[key].username
                      : response.data[key],
                  label: this.columns[i].label,
                };
              }
            }
          }
          for (let i = 0; i < this.nonDislayFields.length; i++) {
            delete this.item[this.nonDislayFields[i]];
          }
          this.coord = {
            lat: response.data.coordinate_lat,
            lon: response.data.coordinate_lon,
          };
          this.name = this.item.name.value;
          this.property_designation = this.item.property_designation.value;
          this.pendingChanges = !!response.data.changemaster[0];
          this.setInsuranceDates();
        })
        .catch(() => {});
    },
    async getLabels() {
      await axiosBase
        .get("api/v1/descriptions/")
        .then((response) => {
          this.columns = response.data;
        })
        .catch(() => {});
    },
    async getChangeHistory() {
      await axiosBase
        .get(`api/v1/changemaster/?insurance_object=${this.$route.params.id}`)
        .then((response) => {
          if (response.data.length) {
            let id = 1;
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].changelog.length) {
                for (let j = 0; j < response.data[i].changelog.length; j++) {
                  this.changes.push({
                    id: id++,
                    field: this.returnFieldLabel(
                      response.data[i].changelog[j].field
                    ),
                    old_value: response.data[i].changelog[j].old_value,
                    new_value: response.data[i].changelog[j].new_value,
                    changed_on: response.data[i].changelog[j].created_on,
                    changed_by:
                      response.data[i].changelog[j].created_by.username,
                    pending:
                      response.data[i].status === "approved"
                        ? "Ja"
                        : "Under utredning",
                  });
                }
              }
            }
          }
        })
        .catch(() => {});
    },
    async getExport(type) {
      await axiosBase({
        url: `api/v1/insuranceobject/${this.$route.params.id}/download/${type}/`, //your url
        method: "GET",
        responseType: "blob", // important
        timeout: 8000,
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            //  "\ufeff" to display special chars in excel such as åäö
            new Blob(["\ufeff", response.data])
          );
          const link = document.createElement("a");
          link.href = url;
          const filename =
            "export" + "." + response.headers["content-type"].split("/").pop();
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {});
    },
  },
  async created() {
    await this.getLabels();
    await this.getItem();
    await this.getChangeHistory();
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  h1 {
    margin-left: 160px !important;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.left-menu-container .list-group {
  width: 60%;
}

.list-group-fullwidth {
  min-width: 100%;
}

button.ol-zoom-out {
  margin-top: -10px !important;
}
a.list-group-item.list-group-item-action {
  padding-left: 8px;
}
#scrolTop {
  margin-top: -17px;
}
.list-group-item {
  font-weight: 200;
}

.ol-viewport {
  width: 79%;
  border-radius: 0.25rem;
  margin-top: 16px;
}
div.ol-overlaycontainer {
  width: 72%;
}
div.ol-layer {
  margin-top: 50px !important;
}
div.ol-zoom ol-unselectable ol-control {
  margin-top: 55px !important;
}
.ol-zoom.ol-unselectable.ol-control {
  margin-top: 23px;
}
.ol-zoom {
  padding-top: 11px;
}
a {
  color: #495057;
  text-decoration: none;
  cursor: pointer;
  margin-left: 14px;
  padding-right: 10px;
}
.list-group-item-action {
  justify-content: center;
  display: flex;
}
.scrollable {
  height: 550px;
  overflow: auto;
  box-shadow: rgb(0 0 0 / 6%) 1px 1px 2px;
  margin-left: 160px;
}
h1 {
  max-width: 82%;
  margin: 50px auto 0;
}
i.posting {
  font-size: small;
  max-width: 300px;
}
.posting {
  font-weight: normal;
  max-width: 300px;
}

.list-group-flush {
  border-radius: 0;
  max-width: 80%;
}
.list-group-flush > .list-group-item {
  padding: 2px;
}

.fa-database:before {
  color: #0176ba;
  padding-right: 10px;
}

.fa-exchange-alt:before {
  color: #0176ba;
  padding-right: 10px;
}

.fa-file-export:before {
  color: #0176ba;
  padding-right: 3px;
}
textarea.text-box {
  width: 400px;
  height: 150px;
  border: none;
  background: none;
  resize: none;
}

.color {
  color: #0176ba;
  font-weight: 600;
  padding-right: 0 !important;
}

.sidebar {
  margin-left: 15px;
  margin-top: 15px;
}

.overlay {
  width: 100%;
  background-color: #fff;
  border-color: rgba(223, 225, 229, 0);
  z-index: 2;
}
.left-menu-container {
  width: 80%;
  padding-left: 16px;
}
.smallMap {
  width: 60%;
}
.page-container {
  width: 98%;
}
</style>
<style>
.smallMap #map {
  margin: 16px 0 0 0;
}
</style>
