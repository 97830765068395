<template>
  <div id="map"></div>
</template>
<script>
/* eslint-disable */
// import openlayer css for style
import "ol/ol.css";
// This is library of openlayers for handle map

import Map from "ol/Map";
import View from "ol/View";
import Feature from "ol/Feature";
import { defaults as defaultControls, ScaleLine } from "ol/control";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { transform } from "ol/proj";
import { OSM, Vector as VectorSource } from "ol/source";

import Point from "ol/geom/Point";
import { Text, Style, Fill } from "ol/style";

export default {
  props: ["coord_in", "enableClick"],
  watch: {
    "coord_in"(newVal, oldVal) {
      document.getElementById("map").innerHTML = "";
      if (this.coord_in.lat !== null && this.coord_in.lat !== 0) {
        this.coord = this.coord_in;
        this.initiateMap(this.coord_in, 16);
      } else if (this.coord_in.lat === 0) {
        this.initiateMap(this.center, 12);
      }
    },
  },
  data() {
    return {
      feature: null,
      icon: null,
      center: { lat: 12.99939, lon: 55.59525 },
      coord: { lat: 0, lon: 0 },
    };
  },
  methods: {
    getFeatureIcon(color, coord) {
      const feature = new Feature({
        // https://openlayers.org/en/latest/examples/icon.html
        //https://codepen.io/chrgl86/pen/ZxwjWy

        //https://github.com/sombriks/vue-openlayers/blob/master/src/ol-marker.vue
        geometry: new Point(coord),
        name: "Center",
      });
        const icon = new Style({
        text: new Text({
          text: "\uF276",
          scale: 1,
          font: "normal 30px FontAwesome",
          offsetY: -15,
          offsetX: 0,
          fill: new Fill({ color: color }),
        }),
      });
      feature.setStyle(icon);
      return feature;
    },
    initiateMap: function (coord, zoomLevel) {
      const center = transform([coord.lat, coord.lon], "EPSG:4326", "EPSG:3857");
      let feature = this.getFeatureIcon("#0176ba", center);
      // create vector layer
      let source = new VectorSource({
        features: (coord !== this.center) ? [feature] : [],
      });
      let vector = new VectorLayer({
        source: source,
      });
      // create title layer
      let raster = new TileLayer({
        source: new OSM(),
      });
      
      const map = new Map({
        controls: defaultControls().extend([
          new ScaleLine({
            bar: true,
          }),
        ]),
        target: "map",
        layers: [raster, vector],
        view: new View({
          center: center,
          zoom: zoomLevel,
        }),
      });
      if (this.enableClick) {
        const self = this;
        map.on("singleclick", function (e, map) {
          self.coord.lat = e.coordinate[0];
          self.coord.lon = e.coordinate[1];
          if (self.feature == null) {
            self.feature = self.getFeatureIcon("#ba0129", coord);
            source.addFeature(self.feature);
          } else {
            self.feature
                .getGeometry()
                .setCoordinates([self.coord.lat, self.coord.lon]);
          }
          const newCoords = transform([self.coord.lat, self.coord.lon], "EPSG:3857", "EPSG:4326");
          console.log(newCoords);
          self.$emit("new_coord", {lat: newCoords[0], lon: newCoords[1]});
        });
      }
    },
  },
};
</script>
<style scoped>
@media (min-width: 768px) {
 .needs-validation{
    padding: 60px;
  }
}

@media (min-width: 1400px) {
 .needs-validation{
    padding: 0;
  }
  }

.ol-layer {
  position: fixed;
}

h1[data-v-14d957f8] {
  max-width: 83% !important;
  margin: 50px auto 0;
}

canvas {
  border-radius: 0.25rem !important;
}

p[data-v-14d957f8] {
  max-width: 83% !important;
  margin: 10px auto 30px;
}

ul {
  padding-left: 14rem !important;
  font-size: 12px;
  margin-top: 4rem !important;
}

.ol-scale-line-inner {
  margin-left: 164px;
  padding-bottom: 12px;
  font-size: 12px;
  width: 133px;
}

#tabBare[data-v-14d957f8] {
  max-width: 61% !important;
}

#map {
  position: absolute;
  padding: 0;
  height: 50% !important;
  margin: 0 0 0 170px;
  max-height: 60%;
  width: 65.6%;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  max-width: 97%;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
  max-width: 97%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#tabBare[data-v-14d957f8] {
  max-width: 60% !important;
}

.ol-zoom,
.ol-unselectable,
.ol-control {
  margin-right: 0!important;
  margin-top: 3px;
  border-style: none;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  border-style: none;
}

.ol-zoom-in {
  margin-top: 5px;
  padding-left: 4px;
  padding-right: 4px;
}

.ol-zoom-out {
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 3px;
  margin-right: 3px;
}

button {
  padding: 7px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.ol-zoom,
.ol-unselectable,
.ol-control {
  margin-right: 83%;
  margin-top: 3px;

  border-style: none;
}

.row {
  margin-top: 1rem !important;
}

.row > * {
  z-index: 1;
  padding-left: 12rem;
}

.openStreetMap {
  padding-left: 4rem;
  padding-top: 15rem;
}

ul {
  padding-left: 1rem !important;
  font-size: 12px;
  margin-top: 0 !important;
}

.ol-scale-singlebar {
  width: 30px !important;
}

.ol-scale-step-text {
  margin-left: 0.25px !important;
  text-align: center;
  min-width: 38.5px;
  margin-right: 100px;
}
</style>
