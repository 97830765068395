<template>
  <div createRowPage>
    <div class="crateContainer">
      <p>
        <a
          v-if="showChangeRequest"
          id="edit"
          class="btn btn-primary"
          data-bs-toggle="collapse"
          href="#makechangerequest"
          role="button"
          aria-expanded="false"
          aria-controls="makechangerequest"
          @click="() => validate(true)"
          >{{ isAdmin ? "Gör Ändring" : "Begär Ändring" }}</a
        >
        <a
          v-else-if="this.bCreate"
          id="create"
          class="btn btn-primary"
          data-bs-toggle="collapse"
          href="#makechangerequest"
          role="button"
          aria-expanded="false"
          aria-controls="makechangerequest"
          @click="() => validate(true)"
          >{{ isAdmin ? "Skapa Nytt Objekt" : "Begär Nytt Objekt" }}</a
        >
        <b-button
          v-if="showChangeHandler"
          variant="success"
          @click="resetLock()"
          class="gul"
          id="create"
          >Ändra förfrågan
        </b-button>
        <b-button
          v-if="noOfChanges && showChangeHandler"
          variant="danger"
          @click="showModal('change')"
          id="godkann"
          class="grön"
          >Godkänn förfrågan med dina nya ändringar
        </b-button>
        <b-button
          v-else-if="showChangeHandler"
          variant="danger"
          @click="showModal('approval')"
          class="grön"
          id="godkann"
          >Godkänn förfrågan
        </b-button>
        <b
          v-else-if="!showChangeRequest && !this.bCreate"
          id="locked"
          class="gul"
          style="color: Red"
          >{{ this.formList.name.value }} är låst för ändring då den har en
          aktiv ändringsbegäran</b
        >
      </p>

      <div v-if="showChangeRequest || this.bCreate" class="row">
        <div class="col">
          <div class="collapse multii-collapse" id="makechangerequest">
            <div class="card card-body" id="SkickaChangeFörmedlare">
              <form @submit.prevent="showModal('change')" class="ändringTop">
                <div class="form-Mortar">
                  <label class="color">Ändringen avser</label>
                  <select
                    v-model="type_of_change"
                    class="form-Mottagare"
                    id="typeofchange"
                    required="true"
                  >
                    <option value="Nyanskaffning">Nyanskaffning</option>
                    <option value="Fastighetsbildning">
                      Fastighetsbildning
                    </option>
                    <option value="Ombyggnad">Ombyggnad</option>
                    <option value="Tillbyggnad">Tillbyggnad</option>
                    <option value="Förändring av verksamhet">
                      Förändring av verksamhet
                    </option>
                    <option value="Avyttring (försäljning)">
                      Avyttring (försäljning)
                    </option>
                    <option value="Beslut om rivning">Beslut om rivning</option>
                    <option value="Rivning genomförd">Rivning genomförd</option>
                    <option value="Objektet totalskadat">
                      Objektet totalskadat
                    </option>
                    <option value="Övrigt (skriv i noteringar)">
                      Övrigt (skriv i noteringar)
                    </option>
                  </select>
                </div>
                <div v-if="showByIntermediary && !isAdmin" class="form-Mortar">
                  <label class="color">Skicka begäran till</label>
                  <select
                    v-model="byIntermediary"
                    :disabled="!itemIntermediary"
                    class="form-Mottagare"
                    id="intermediary"
                    required="true"
                  >
                    <option v-if="!itemIntermediary" disabled :value="false">
                      Skicka till admin
                    </option>
                    <option :value="true">Skicka till förmedlare</option>
                    <option :value="false">Skicka till admin</option>
                  </select>
                </div>

                <button
                  v-if="noOfChanges && !this.bCreate"
                  type="submit"
                  class="btn btn-primary"
                  id="requestchange"
                >
                  {{ isAdmin ? "Ändra Objekt" : "Skicka ändringsbegäran" }}
                </button>
                <button
                  v-else-if="noOfChanges && this.bCreate"
                  type="submit"
                  class="btn btn-primary"
                  id="requestnew"
                >
                  {{
                    isAdmin ? "Skapa Objekt" : "Skicka begäran om nytt objekt"
                  }}
                </button>
                <i v-else id="NoChangeInput">Inga ändringar gjorda</i>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6">
      <form @submit.prevent class="needs-validation" novalidate>
        <!--        FIRST ROW OF FIELDS      -->

        <div class="form-row align-items-baseline">
          <div class="col-md-3 mb-3">
            <FormTextInputField
              fieldname="insurance_company"
              :field="formList.insurance_company"
              @updatedValue="(n) => haveChanged(n, 'insurance_company')"
              @help="() => showInfoModal('insurance_company')"
            />
          </div>
          <div class="col-2 mb-1">
            <FormSelectInputField
              fieldname="municipality"
              :field="formList.municipality"
              @updatedValue="(n) => haveChanged(n, 'municipality')"
              @help="() => showInfoModal('municipality')"
              :values="municipalities"
            />
          </div>
          <div class="col-3 mb-1">
            <FormTextInputField
              fieldname="internal_municipality_id"
              :field="formList.internal_municipality_id"
              @updatedValue="(n) => haveChanged(n, 'internal_municipality_id')"
              @help="() => showInfoModal('internal_municipality_id')"
            />
          </div>
          <div class="col-2 mb-1">
            <FormTextInputField
              fieldname="property_owner"
              :field="formList.property_owner"
              @updatedValue="(n) => haveChanged(n, 'property_owner')"
              @help="() => showInfoModal('property_owner')"
            />
          </div>
          <div class="col-2 mb-1">
            <FormTextInputField fieldname="id" :field="formList.id" />
          </div>
        </div>

        <!--        SECOND ROW OF FIELDS       -->

        <div class="form-row align-items-baseline">
          <div class="col-3 mb-1">
            <FormTextInputField
              fieldname="management"
              :field="formList.management"
              @updatedValue="(n) => haveChanged(n, 'management')"
              @help="() => showInfoModal('management')"
            />
          </div>
          <div class="col-3 mb-1">
            <FormTextInputField
              fieldname="street_name"
              :field="formList.street_name"
              @updatedValue="(n) => haveChanged(n, 'street_name')"
              @help="() => showInfoModal('street_name')"
            />
          </div>
          <div class="col-1 mb-1">
            <FormTextInputField
              fieldname="street_number"
              :field="formList.street_number"
              @updatedValue="(n) => haveChanged(n, 'street_number')"
              @help="() => showInfoModal('street_number')"
            />
          </div>
          <div class="col-2 mb-1">
            <FormTextInputField
              fieldname="zip_code"
              :field="formList.zip_code"
              @updatedValue="(n) => haveChanged(n, 'zip_code')"
              @help="() => showInfoModal('zip_code')"
            />
          </div>
          <div class="col mb-1">
            <FormTextInputField
              fieldname="city"
              :field="formList.city"
              @updatedValue="(n) => haveChanged(n, 'city')"
              @help="() => showInfoModal('city')"
            />
          </div>
        </div>

        <!--        THIRD ROW OF FIELDS       -->

        <div class="form-row align-items-baseline">
          <div class="col-4 mb-1">
            <FormTextInputField
              fieldname="name"
              :field="formList.name"
              @updatedValue="(n) => haveChanged(n, 'name')"
              @help="() => showInfoModal('name')"
            />
          </div>
          <div class="col-3 mb-1">
            <FormTextInputField
              fieldname="property_designation"
              :field="formList.property_designation"
              @updatedValue="(n) => haveChanged(n, 'property_designation')"
              @help="() => showInfoModal('property_designation')"
            />
          </div>
          <div class="col mb-1">
            <FormTextInputField
              fieldname="group"
              :field="formList.group"
              @updatedValue="(n) => haveChanged(n, 'group')"
              @help="() => showInfoModal('group')"
            />
          </div>
          <div class="col mb-1">
            <FormTextInputField
              fieldname="official_id"
              :field="formList.official_id"
              @updatedValue="(n) => haveChanged(n, 'official_id')"
              @help="() => showInfoModal('official_id')"
            />
          </div>
        </div>

        <!--        FOURTH ROW OF FIELDS       -->

        <div class="form-row align-items-baseline">
          <div class="col-3 mb-1">
            <FormTextInputField
              fieldname="external_id"
              :field="formList.external_id"
              @updatedValue="(n) => haveChanged(n, 'external_id')"
              @help="() => showInfoModal('external_id')"
            />
          </div>
          <div class="col-3 mb-1">
            <FormSelectInputField
              fieldname="category_external"
              :field="formList.category_external"
              @updatedValue="(n) => haveChanged(n, 'category_external')"
              @help="() => showInfoModal('category_external')"
              :values="categories_external"
            />
          </div>
          <div class="col-3 mb-1">
            <FormTextInputField
              fieldname="electrical_id"
              :field="formList.electrical_id"
              @updatedValue="(n) => haveChanged(n, 'electrical_id')"
              @help="() => showInfoModal('electrical_id')"
            />
          </div>
          <div class="col-3 mb-1">
            <FormTextInputField
              fieldname="specification"
              :field="formList.specification"
              @updatedValue="(n) => haveChanged(n, 'specification')"
              @help="() => showInfoModal('specification')"
            />
          </div>
        </div>

        <!--        FIFTH ROW OF FIELDS       -->

        <div class="form-row align-items-baseline">
          <div class="col-3 mb-1" v-if="isIntern">
            <FormSelectInputField
              fieldname="category_internal"
              :field="formList.category_internal"
              @updatedValue="(n) => haveChanged(n, 'category_internal')"
              @help="() => showInfoModal('category_internal')"
              :values="categories_internal"
            />
          </div>
          <div class="col-2 mb-1">
            <FormSelectInputField
              fieldname="form_of_insurance"
              :field="formList.form_of_insurance"
              :values="form_of_insurances"
              @updatedValue="(n) => haveChanged(n, 'form_of_insurance')"
              @help="() => showInfoModal('form_of_insurance')"
            />
          </div>
          <div
            class="col-2 mb-1"
            v-if="isIntern || hasSelectedOptionKey('form_of_insurance', 'HV')"
          >
            <FormTextInputField
              fieldname="insurance_amount"
              :field="formList.insurance_amount"
              @updatedValue="(n) => haveChanged(n, 'insurance_amount')"
              @focus="(n) => returnNumber(n, 'insurance_amount')"
              @blur="(n) => blurCurrency(n, 'insurance_amount')"
              @help="() => showInfoModal('insurance_amount')"
              @keypress="(n) => isNumber(n)"
            />
          </div>
          <div
            class="col-2 mb-1"
            v-if="isIntern || hasSelectedOptionKey('form_of_insurance', 'FR')"
          >
            <FormTextInputField
              fieldname="first_risk"
              :field="formList.first_risk"
              @updatedValue="(n) => haveChanged(n, 'first_risk')"
              @focus="(n) => returnNumber(n, 'first_risk')"
              @blur="(n) => blurCurrency(n, 'first_risk')"
              @help="() => showInfoModal('first_risk')"
              @keypress="(n) => isNumber(n)"
            />
          </div>
          <div class="col mb-1" v-if="isIntern">
            <FormTextInputField
              fieldname="premium"
              :field="formList.premium"
              @updatedValue="(n) => haveChanged(n, 'premium')"
              @focus="(n) => returnNumber(n, 'premium')"
              @blur="(n) => blurCurrency(n, 'premium')"
              @help="() => showInfoModal('premium')"
              @keypress="(n) => isNumber(n)"
            />
          </div>
        </div>

        <!--        SIXTH ROW OF FIELDS      -->

        <div class="form-row align-items-baseline">
          <div class="col-2 mb-1">
            <FormTextInputField
              fieldname="calculated_value"
              :field="formList.calculated_value"
              @updatedValue="(n) => haveChanged(n, 'calculated_value')"
              @focus="(n) => returnNumber(n, 'calculated_value')"
              @blur="(n) => blurCurrency(n, 'calculated_value')"
              @help="() => showInfoModal('calculated_value')"
              @keypress="(n) => isNumber(n)"
            />
          </div>
          <div class="col-2 mb-1">
            <FormTextInputField
              fieldname="calculation_method"
              :field="formList.calculation_method"
              @updatedValue="(n) => haveChanged(n, 'calculation_method')"
              @help="() => showInfoModal('calculation_method')"
            />
          </div>
          <div class="col-2 mb-1">
            <FormTextInputField
              fieldname="calculation_date"
              :field="formList.calculation_date"
              @updatedValue="(n) => haveChanged(n, 'calculation_date')"
              @help="() => showInfoModal('calculation_date')"
            />
          </div>
          <div class="col-2 mb-1" v-if="isIntern">
            <FormTextInputField
              fieldname="calculated_value_content"
              :field="formList.calculated_value_content"
              @updatedValue="(n) => haveChanged(n, 'calculated_value_content')"
              @focus="(n) => returnNumber(n, 'calculated_value_content')"
              @blur="(n) => blurCurrency(n, 'calculated_value_content')"
              @help="() => showInfoModal('calculated_value_content')"
              @keypress="(n) => isNumber(n)"
            />
          </div>
          <div class="col-2 mb-1" v-if="isIntern">
            <FormTextInputField
              fieldname="calculated_value_total"
              :field="formList.calculated_value_total"
              @updatedValue="(n) => haveChanged(n, 'calculated_value_total')"
              @focus="(n) => returnNumber(n, 'calculated_value_total')"
              @blur="(n) => blurCurrency(n, 'calculated_value_total')"
              @help="() => showInfoModal('calculated_value_total')"
              @keypress="(n) => isNumber(n)"
            />
          </div>
        </div>

        <!--        SEVENTH ROW OF FIELDS                   -->

        <div class="form-row align-items-baseline">
          <div class="col-3 mb-1">
            <FormTextInputField
              fieldname="requested_insurance_from_date"
              :field="formList.requested_insurance_from_date"
              @updatedValue="
                (n) => haveChanged(n, 'requested_insurance_from_date')
              "
              @focus="
                () =>
                  (this.formList.requested_insurance_from_date.type = 'date')
              "
              @blur="
                () =>
                  (this.formList.requested_insurance_from_date.type = 'text')
              "
              @help="() => showInfoModal('requested_insurance_from_date')"
            />
          </div>
          <div class="col-3 mb-1">
            <FormTextInputField
              fieldname="requested_insurance_to_date"
              :field="formList.requested_insurance_to_date"
              :placeholder="
                formList.requested_insurance_from_date.value
                  ? 'Tillsvidare'
                  : ''
              "
              @updatedValue="
                (n) => haveChanged(n, 'requested_insurance_to_date')
              "
              @focus="
                () => (this.formList.requested_insurance_to_date.type = 'date')
              "
              @blur="
                () => (this.formList.requested_insurance_to_date.type = 'text')
              "
              @help="() => showInfoModal('requested_insurance_to_date')"
            />
          </div>
          <div class="col-2 mb-1" v-if="isIntern">
            <FormTextInputField
              fieldname="uw"
              :field="formList.uw"
              @updatedValue="(n) => haveChanged(n, 'uw')"
              @help="() => showInfoModal('uw')"
            />
          </div>
          <div class="col-2 mb-1">
            <FormSelectInputField
              fieldname="cultural_protection_type"
              :field="formList.cultural_protection_type"
              @updatedValue="(n) => haveChanged(n, 'cultural_protection_type')"
              @help="() => showInfoModal('cultural_protection_type')"
              :values="cultural_protection_types"
            />
          </div>
        </div>

        <!--        EIGHT ROW OF FIELDS              -->

        <div class="form-row align-items-baseline">
          <div class="col-3 mb-1">
            <FormTextInputField
              fieldname="insurance_from_date"
              :field="formList.insurance_from_date"
              @updatedValue="(n) => haveChanged(n, 'insurance_from_date')"
              @focus="() => (this.formList.insurance_from_date.type = 'date')"
              @blur="() => (this.formList.insurance_from_date.type = 'text')"
              @help="() => showInfoModal('insurance_from_date')"
            />
          </div>
          <div class="col-2 mb-1">
            <FormTextInputField
              fieldname="insurance_to_date"
              :field="formList.insurance_to_date"
              :placeholder="
                !formList.insurance_from_date.value
                  ? 'Ej försäkrad'
                  : 'Tillsvidare'
              "
              @updatedValue="(n) => haveChanged(n, 'insurance_to_date')"
              @focus="() => (this.formList.insurance_to_date.type = 'date')"
              @blur="() => (this.formList.insurance_to_date.type = 'text')"
              @help="() => showInfoModal('insurance_to_date')"
            />
          </div>
          <div class="col-2 mb-1" v-if="isIntern">
            <FormTextInputField
              fieldname="adjusted_premium"
              :field="formList.adjusted_premium"
              @updatedValue="(n) => haveChanged(n, 'adjusted_premium')"
              @help="() => showInfoModal('adjusted_premium')"
            />
          </div>
          <div class="col-3 mb-1">
            <FormTextInputField
              fieldname="created_by"
              :field="formList.created_by"
              :placeholder="user.username"
              @updatedValue="(n) => haveChanged(n, 'created_by')"
              @help="() => showInfoModal('created_by')"
            />
          </div>
          <div class="col-2 mb-1">
            <FormTextInputField
              fieldname="created_on"
              :field="formList.created_on"
              @updatedValue="(n) => haveChanged(n, 'created_on')"
              @help="() => showInfoModal('created_on')"
            />
          </div>
        </div>

        <!--        10TH ROW OF FIELDS                -->

        <div class="form-row align-items-baseline">
          <div class="col-2 mb-1">
            <FormTextInputField
              fieldname="tenant"
              :field="formList.tenant"
              @updatedValue="(n) => haveChanged(n, 'tenant')"
              @help="() => showInfoModal('tenant')"
            />
          </div>
          <div class="col-1 mb-1">
            <FormTextInputField
              fieldname="build_year"
              :field="formList.build_year"
              @updatedValue="(n) => haveChanged(n, 'build_year')"
              @help="() => showInfoModal('build_year')"
            />
          </div>
          <div class="col-3 mb-1">
            <FormSelectInputField
              fieldname="facility_class"
              :field="formList.facility_class"
              @updatedValue="(n) => haveChanged(n, 'facility_class')"
              @help="() => showInfoModal('facility_class')"
              :values="facility_classes"
              :help="true"
            />
          </div>
          <div class="col-1 mb-1">
            <FormTextInputField
              fieldname="gross_floor_area"
              :field="formList.gross_floor_area"
              @updatedValue="(n) => haveChanged(n, 'gross_floor_area')"
              @help="() => showInfoModal('gross_floor_area')"
            />
          </div>
          <div class="col-2 mb-1">
            <FormSelectInputField
              fieldname="basement_included"
              :field="formList.basement_included"
              @updatedValue="(n) => haveChanged(n, 'basement_included')"
              @help="() => showInfoModal('basement_included')"
              :values="yes_no"
            />
          </div>
          <div class="col-2 mb-1" v-if="isIntern">
            <FormTextInputField
              fieldname="number_of_floors"
              :field="formList.number_of_floors"
              @updatedValue="(n) => haveChanged(n, 'number_of_floors')"
              @help="() => showInfoModal('number_of_floors')"
            />
          </div>
        </div>

        <!--        11TH ROW OF FIELDS                -->

        <div class="form-row align-items-end">
          <div class="col-2 mb-1">
            <FormTextInputField
              fieldname="latest_renovation"
              :field="formList.latest_renovation"
              @updatedValue="(n) => haveChanged(n, 'latest_renovation')"
              @help="() => showInfoModal('latest_renovation')"
            />
          </div>
          <div class="col-2 mb-1">
            <FormSelectInputField
              fieldname="fire_alarm"
              :field="formList.fire_alarm"
              :values="fire_alarms"
              :help="true"
              @updatedValue="(n) => haveChanged(n, 'fire_alarm')"
              @help="() => showInfoModal('fire_alarm')"
            />
          </div>
          <div class="col-3 mb-1">
            <FormSelectInputField
              fieldname="fire_protection"
              :field="formList.fire_protection"
              @updatedValue="(n) => haveChanged(n, 'fire_protection')"
              @help="() => showInfoModal('fire_protection')"
              :values="fire_protections"
            />
          </div>
          <div class="col-2 mb-1">
            <FormSelectInputField
              fieldname="burglar_alarm"
              :field="formList.burglar_alarm"
              :values="burglar_alarms"
              @updatedValue="(n) => haveChanged(n, 'burglar_alarm')"
              @help="() => showInfoModal('burglar_alarm')"
            />
          </div>
          <div class="col-1 mb-1">
            <FormTextInputField
              fieldname="burglar_alarm_type"
              :field="formList.burglar_alarm_type"
              @help="() => showInfoModal('burglar_alarm_type')"
            />
          </div>
        </div>
        <div class="form-row align-items-end">
          <div class="col-2 mb-1">
            <FormSelectInputField
              fieldname="solar_cells"
              :field="formList.solar_cells"
              @updatedValue="(n) => haveChanged(n, 'solar_cells')"
              @help="() => showInfoModal('solar_cells')"
              :values="solar_cells"
            />
          </div>
          <div class="col-2 mb-1">
            <FormSelectInputField
              fieldname="energy_storage"
              :field="formList.energy_storage"
              @updatedValue="(n) => haveChanged(n, 'energy_storage')"
              @help="() => showInfoModal('energy_storage')"
              :values="yes_no"
            />
          </div>
          <div class="col-2 mb-1">
            <FormTextInputField
              fieldname="other_responsible"
              :field="formList.other_responsible"
              @updatedValue="(n) => haveChanged(n, 'other_responsible')"
              @help="() => showInfoModal('other_responsible')"
            />
          </div>
          <div class="col-2 mb-1">
            <FormTextInputField
              fieldname="other_responsible_email"
              :field="formList.other_responsible_email"
              @updatedValue="(n) => haveChanged(n, 'other_responsible_email')"
              @help="() => showInfoModal('other_responsible_email')"
            />
          </div>
        </div>

        <!--        NOTES            -->

        <div class="form-row">
          <div class="input-group">
            <FormTextAreaField
              fieldname="external_note"
              :field="formList.external_note"
              :rows="5"
              @updatedValue="(n) => haveChanged(n, 'external_note')"
            />
          </div>
        </div>
        <div class="form-row" v-if="isIntern">
          <div class="input-group">
            <FormTextAreaField
              fieldname="internal_note"
              :field="formList.internal_note"
              :rows="5"
              @updatedValue="(n) => haveChanged(n, 'internal_note')"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="input-group">
            <FormTextAreaField
              fieldname="specific_terms"
              :field="formList.specific_terms"
              :posts="specific_terms"
              subTitle="Lägg till villkor för försäkringsskydd"
              @updatedValue="(n) => haveChanged(n, 'specific_terms')"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="input-group">
            <FormTextAreaField
              fieldname="insurance_company_information"
              :field="formList.insurance_company_information"
              :posts="insurance_company_information"
              subTitle="Lägg till upplysning till försäkringsbolag"
              @updatedValue="
                (n) => haveChanged(n, 'insurance_company_information')
              "
            />
          </div>
        </div>

        <!--        COORDINATES AND MAP            -->

        <div class="form-row">
          <div class="col-2 mb-1">
            <FormTextInputField
              fieldname="coordinate_lat"
              :field="formList.coordinate_lat"
              @updatedValue="(n) => haveChanged(n, 'coordinate_lat')"
            />
          </div>
          <div class="col-2 mb-1" id="hamta">
            <FormTextInputField
              fieldname="coordinate_lon"
              :field="formList.coordinate_lon"
              @updatedValue="(n) => haveChanged(n, 'coordinate_lon')"
            />
          </div>
          <a
            class="btn btn-primary"
            id="hittaKoordinator"
            @click="updateCoordinates()"
          >
            Hämta Koordinater Från Karta
          </a>
        </div>

        <b-row class="col-xs-6">
          <maptest
            class="createMap"
            :coord_in="coord"
            :enableClick="true"
            @new_coord="getCoordinates"
          ></maptest>
        </b-row>
      </form>
    </div>
  </div>
  <confirmation-modal
    v-if="displayModal"
    :body="modal_body + formList.name.value"
    :header="modal_header"
    @close-modal-event="hideModal"
  />
  <info-modal
    v-if="displayInfoModal"
    :body="modal_body"
    :header="modal_header"
    :content="modal_content"
    @close-modal-event="hideInfoModal"
  />
</template>

<script>
import { axiosBase } from "@/axios-api";
import maptest from "@/components/maptest";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import { useToast } from "vue-toastification";
import InfoModal from "@/components/InfoModal.vue";
import FormTextInputField from "@/components/FormTextInputField.vue";
import FormSelectInputField from "@/components/FormSelectInputField.vue";
import FormTextAreaField from "@/components/FormTextAreaField.vue";
import { insuranceObjectTemplate } from "../components/InsuranceObjectTemplate";
import { insuranceObjectFields } from "../components/InsuranceObjectFields";
import _ from "lodash";

export default {
  components: {
    maptest,
    ConfirmationModal,
    InfoModal,
    FormTextInputField,
    FormSelectInputField,
    FormTextAreaField,
  },
  watch: {
    $route(to, from) {
      if (to.name === "Create" && from.name === "Edit") {
        this.checkOrigin();
        this.resetFormList();
      } else if (to.name === "Edit" && from.name === "Create") {
        this.checkOrigin();
        this.resetFormList();
      }
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      facility_class_info: [
        {
          value: "Byggnadsklass 1",
          description:
            "Byggnad som har alla byggnadsdelar (ytterväggar, stomme, tak, mellanbotten) av sten eller betong, eller byggnad som har två eller flera mellanbottnar och alla av sten eller betong, oavsett utförande av ytterväggar, stomme och tak.",
        },
        {
          value: "Byggnadsklass 2",
          description:
            "Byggnad som har alla ytterväggar av sten eller betong, men där byggnadssättet i övrigt avviker från byggnadsklass 1 ovan och innehåller byggnadsdelar (stomme, tak, mellanbotten) av stål, limträ, plåt, trä eller plast.",
        },
        {
          value: "Byggnadsklass 3",
          description:
            "Byggnad som har yttervägg av trä eller plast som är utvändigt skyddade med revetering eller annan svårantändlig beklädnad. Övriga byggnadsdelar (stomme, tak, mellanbotten) är till övervägande delar är av trä eller plast.",
        },
        {
          value: "Byggnadsklass 4",
          description:
            "Byggnad som har yttervägg och ytterbeklädnad av brännbar konstruktion som trä eller plast och där även övriga byggnadsdelar (stomme, tak, mellanbotten) till övervägande delar är av trä eller plast.",
        },
      ],
      fire_alarm_info: [
        {
          value: "Nej",
          description: "Brandlarm saknas",
        },
        {
          value: "B",
          description:
            "Avser ett heltäckande brandlarm enligt regelverket SBF 110 vilket också innebär att det är vidarekopplat till larmcentral/räddningstjänst",
        },
        {
          value: "BA",
          description:
            "Avser ett brandlarm installerat enligt regelverket SBF 110, bilaga A. Larmet ska därmed vara vidarekopplat till larmcentral.",
        },
        {
          value: "+",
          description:
            "Ovanstående beteckningar B och BA kan kompletteras med ett + om larminstallationen är mer omfattande än regelverket, det kan exempelvis vara fler detektorer än vad regelverket kräver eller att en takfotskabel installerats.",
        },
        {
          value: "-",
          description:
            "Ovanstående beteckningar B och BA kan kompletteras med ett – om avsteg gjorts i förhållande till regelverket som exempelvis färre detektorer.",
        },
        {
          value: "U",
          description:
            "Indikerar att det finns ett utrymningslarm installerat i byggnaden. Med utrymningslarm avses anläggning för att åstadkomma trygg och säker utrymning. Start av anläggningen sker automatiskt vid rökdetektering och manuellt med tryckknapp. Larmet är inte vidarekopplat till larmcentral eller räddningstjänst.",
        },
      ],
      user: this.$store.getters.userDetail,
      displayModal: false,
      displayInfoModal: false,
      modal_type: "",
      modal_body: [],
      modal_header: "",
      modal_content: "",
      itemIntermediary: 0,
      changeMaster: 0,
      changeMasterStatus: "",
      type_of_change: "",
      byIntermediary: false,
      bCreate: false,
      pendingChanges: false,
      submitForm: {},
      formList: _.cloneDeep(insuranceObjectFields),
      item: _.cloneDeep(insuranceObjectTemplate),
      specific_terms: [],
      insurance_company_information: [],
      coord: {
        lat: 0,
        lon: 0,
      },
      new_coord: null,
      municipalities: [],
      property_owners: [],
      categories_external: [],
      categories_internal: [],
      facility_classes: [],
      fire_alarms: [],
      burglar_alarms: [],
      fire_protections: [],
      form_of_insurances: [],
      solar_cells: [],
      attributes: [],
      cultural_protection_types: [],
      yes_no: [
        { value: "JA", text: "JA" },
        { value: "NEJ", text: "NEJ" },
      ],
      components: {
        maptest,
      },
    };
  },
  mounted() {
    this.resetFormList();
    this.setDynamicPropertyValues();
    this.checkOrigin();
  },
  computed: {
    showChangeHandler: function () {
      return !!(
        (this.isAdmin && this.changeMaster) ||
        (this.changeMasterStatus === "at_intermediary" &&
          this.user.group === "INTERMEDIARY")
      );
    },
    showChangeRequest: function () {
      return !this.pendingChanges && !this.bCreate;
    },
    noOfChanges: function () {
      return Object.keys(this.submitForm).length;
    },
    showByIntermediary: function () {
      return !this.isAdmin && this.user.group !== "INTERMEDIARY";
    },
    isAdmin: function () {
      return this.user.isAdmin === "true";
    },
    isIntern: function () {
      return this.user.group === "INTERN";
    },
  },
  methods: {
    validateField(field) {
      const emailTest =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (field.required && !field.value) return false;
      if (field.type == "email" && field.value && !emailTest.test(field.value))
        return false;
      return true;
    },
    validate(doWarning) {
      if (doWarning) this.validateWarning();
      return this.validateError();
    },
    validateError() {
      let errorFields = [];
      for (let key in this.formList) {
        if (!this.validateField(this.formList[key])) {
          this.formList[key].invalid = true;
          errorFields.push(this.formList[key].label);
        }
      }
      if (errorFields.length)
        this.toast.error(
          "Rätta till fält som saknar giltigt värde:\n" + errorFields.join("\n")
        );
      return errorFields.length === 0;
    },
    validateWarning() {
      let errorFields = [];
      for (let key in this.formList) {
        if (this.formList[key].warning && !this.formList[key].value) {
          errorFields.push(this.formList[key].label);
        }
      }
      if (errorFields.length)
        this.toast.warning(
          "Följande fält saknar data:\n" + errorFields.join("\n"),
          { timeout: false }
        );
      return errorFields.length === 0;
    },
    setDynamicPropertyValues() {
      if (this.isAdmin) {
        for (let key in this.formList) {
          if (this.formList[key].warning_admin)
            this.formList[key].warning = true;
          if (this.formList[key].required_admin)
            this.formList[key].required = true;
        }
      } else {
        for (let key in this.formList) {
          if (this.formList[key].required_nonadmin)
            this.formList[key].required = true;
          if (this.formList[key].disable_nonadmin)
            this.formList[key].disable = true;
          if (this.formList[key].warning_nonadmin)
            this.formList[key].warning = true;
        }
      }
    },
    hasSelectedOptionKey(field, key) {
      const currentId = this.submitForm[field] ?? this.formList[field].value;
      if (currentId > 0)
        return this.attributes.find((x) => x.value == currentId).key === key;
      return false;
    },
    returnNumber(value, key) {
      if (value === null || value === "") return null;
      const reversedValue = value
        .replace("kr", "")
        .replace(",", ".")
        .replace(/\s/g, "");
      this.formList[key].value = Number(reversedValue);
    },
    blurCurrency(value, key) {
      this.formList[key].value = this.$helpers.returnCurrency(value);
    },
    isNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    showInfoModal(field) {
      this.modal_header = this.formList[field].label;
      this.modal_content = this.formList[field].help;
      if (field === "facility_class") {
        this.modal_body = this.facility_class_info;
      } else if (field === "fire_alarm") {
        this.modal_body = this.fire_alarm_info;
      }
      this.displayInfoModal = true;
    },
    showModal(action) {
      this.modal_type = action;
      if (this.validate(false)) {
        if (this.modal_type === "change") {
          this.modal_header = "Bekräfta begäran";
          if (this.bCreate) {
            this.modal_body = "Vänligen bekräfta ändring av nya objektet: ";
          } else {
            this.modal_body = "Vänligen bekräfta ändring av objektet: ";
          }
        } else if (this.modal_type === "approval") {
          this.modal_header = "Bekräfta godkännande";
          this.modal_body = "Vänligen bekräfta godkännande av objektet: ";
        }
        if (this.bCreate && this.modal_type === "change") {
          this.modal_body = "Vänligen bekräfta ändring av nya objektet: ";
        }
        this.displayModal = true;
      }
    },
    hideInfoModal() {
      this.displayInfoModal = false;
      this.modal_header = "";
      this.modal_body = "";
      this.modal_content = "";
    },
    hideModal(value) {
      this.displayModal = false;
      if (this.modal_type === "change" || this.modal_type === "approval") {
        if (value) {
          if (this.modal_type === "change") {
            this.requestChange();
          } else if (this.modal_type === "approval") {
            this.approveRequest();
          }
        } else {
          this.toast.info("Begäran avbruten");
        }
      }
    },
    getCoordinates(value) {
      this.new_coord = value;
    },
    updateCoordinates() {
      if (!this.new_coord) {
        this.toast.info("Vänligen ange en punkt i kartan att hämta");
      } else {
        this.formList.coordinate_lat.value = this.new_coord.lat;
        this.formList.coordinate_lon.value = this.new_coord.lon;
        this.haveChanged(this.formList.coordinate_lat.value, "coordinate_lat");
        this.haveChanged(this.formList.coordinate_lon.value, "coordinate_lon");
      }
    },
    resetLock() {
      for (let key in this.formList) this.formList[key].locked = false;
      this.toast.info(
        "Formuläret är upplåst. Nu kan du lägga till, eller ändra, befintliga ändringar"
      );
    },
    haveChanged(new_value, key) {
      this.formList[key].invalid = !this.validateField(this.formList[key]);

      if (new_value === "" && this.formList[key].type === "date") {
        new_value = null;
      }
      if (
        this.formList[key].type === "select" ||
        this.formList[key].type === "number" ||
        this.formList[key].currency
      ) {
        if (!new_value) new_value = "";
        else if (!isNaN(new_value)) new_value = Number(new_value);
      }
      if (key === "municipality") {
        this.itemIntermediary = 0;
        for (let i = 0; i < this.municipalities.length; i++) {
          if (this.municipalities[i].value.toString() === new_value) {
            this.itemIntermediary = this.municipalities[i].intermediaries[0];
          }
        }
      }
      if (new_value !== this.item[key]) {
        this.submitForm[key] = new_value;
        this.formList[key].styleObject.fontWeight = "bold";
        this.formList[key].styleObject.backgroundColor = "papayawhip";
      } else {
        if (this.$helpers.inDict(key, this.submitForm)) {
          if (this.submitForm[key] !== new_value) {
            delete this.submitForm[key];
            this.formList[key].styleObject = {};
          }
        }
      }
    },
    resetStyles() {
      for (let key in this.formList) this.formList[key].styleObject = {};
    },
    resetFormList() {
      this.submitForm = {};
      this.specific_terms = [];
      this.insurance_company_information = [];
      this.itemIntermediary = 0;
      this.changeMaster = 0;
      this.changeMasterStatus = "";
      this.formList = _.cloneDeep(insuranceObjectFields);
      this.resetStyles();
    },
    async resetPage() {
      this.resetStyles();
      await this.loadItem();
      this.submitForm = {};
      this.byIntermediary = false;
    },
    async requestChange() {
      if (Object.keys(this.submitForm).length) {
        await this.sendRequest();
      } else {
        this.toast.info("Inga ändringar gjorda");
      }
    },
    async sendRequest() {
      if (this.byIntermediary) {
        this.submitForm["intermediary"] = this.itemIntermediary;
      }
      if (this.type_of_change) {
        this.submitForm["type_of_change"] = this.type_of_change;
      }
      const submitProps = this.getSubmitProperties(this.submitForm);
      await axiosBase(submitProps.req)
        .then((response) => {
          if (submitProps.email) this.sendMail(response.data.id);
          this.toast.success(submitProps.toast);
          this.$router.push({
            name: "Details",
            params: { id: response.data.id },
          });
        })
        .catch(() => this.toast.error("Servern returnerade ett felmeddelande"));
    },
    getSubmitProperties(data) {
      let retVal = {
        req: {
          url: this.bCreate
            ? `api/v1/insuranceobject/`
            : `api/v1/insuranceobject/${this.$route.params.id}/`,
          method: this.bCreate ? "post" : "patch",
          data: data,
        },
        email: this.isAdmin,
      };

      if (this.user.isAdmin === "true")
        retVal.toast = this.bCreate
          ? "Nytt objekt skapat"
          : "Ändringar genomförda";
      else if (this.byIntermediary)
        retVal.toast = this.bCreate
          ? "Begäran om nytt objekt skickat till Förmedlare"
          : "Ändringsbegäran skickad till Förmedlare";
      else
        retVal.toast = this.bCreate
          ? "Begäran om nytt objekt skickat till Admin"
          : "Ändringsbegäran skickad till Admin";

      return retVal;
    },
    sendMail(id) {
      // Should not wait for email
      axiosBase
        .post(`api/v1/insuranceobject/${id}/send_mail/`, {
          changeMaster: this.changeMaster,
        })
        .then(() => {})
        .catch(() => {});
    },
    async approveRequest() {
      await axiosBase
        .post(
          `api/v1/insuranceobject/${this.$route.params.id}/approve_change_master/`
        )
        .then(() => {
          this.sendMail(this.$route.params.id);
          this.resetPage();
          const msg =
            this.changeMasterStatus === "at_admin"
              ? "Ändringsförfrågan godkänd"
              : "Ändringsförfrågan godkänd av förmedlare och skickad till Admin";

          this.toast.success(msg);

          this.$router.push({
            name: "Details",
            params: { id: this.$route.params.id },
          });
        })
        .catch(() => this.toast.error("Servern returnerade ett felmeddelande"));
    },
    async loadItem() {
      await axiosBase
        .get(`api/v1/insuranceobject/${this.$route.params.id}/`)
        .then((response) => {
          this.item = response.data;
          this.specific_terms = response.data["specific_terms"];
          this.insurance_company_information =
            response.data["insurance_company_information"];
          this.item.specific_terms = "";
          this.item.insurance_company_information = "";
          if (this.item.municipality.intermediary_municipality) {
            this.itemIntermediary =
              this.item.municipality.intermediary_municipality[0];
          }
          this.coord = {
            lat: this.item.coordinate_lat ? this.item.coordinate_lat : 0,
            lon: this.item.coordinate_lon ? this.item.coordinate_lon : 0,
          };
          if (this.item.municipality.intermediaries)
            this.itemIntermediary = this.item.municipality.intermediaries[0];

          if (this.item.changemaster.length) {
            this.pendingChanges = true;
            const cm = this.item.changemaster[0];
            this.changeMasterStatus = cm.status;
            this.changeMaster = cm.id;
            for (let i = 0; i < cm.changelog.length; i++)
              this.formList[cm.changelog[i].field].styleObject = {
                color: "Red",
                fontWeight: "bold",
              };
            for (let key in this.formList) this.formList[key].locked = true;
          }
          for (let key in this.formList) {
            if (this.item[key]) {
              if (this.item[key].id) this.item[key] = this.item[key].id; // Sets the FK Id as value
              this.formList[key].value = this.item[key];
            } else {
              this.item[key] = this.formList[key].type === "date" ? null : "";
            }

            if (this.formList[key].currency) {
              this.formList[key].value = this.$helpers.returnCurrency(
                this.item[key]
              );
              this.item[key] = this.item[key] ? this.item[key].toString() : "";
            }

            if (key === "created_by")
              this.formList[key].value = this.item[key].username;
          }
        });
    },
    clearOptions() {
      this.municipalities = [];
      this.property_owners = [];
      this.fire_alarms = [];
      this.categories_external = [];
      this.categories_internal = [];
      this.facility_classes = [];
      this.fire_protections = [];
    },
    async getLabels() {
      await axiosBase.get("api/v1/descriptions/").then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          this.formList[response.data[i].key].label = response.data[i].label;
          this.formList[response.data[i].key].help = response.data[i].help;
        }
      });
    },
    async getMunicipalities() {
      await axiosBase.get("api/v1/municipality/").then((response) => {
        this.municipalities = response.data.map((x) => {
          return { value: x.id, text: x.name };
        });
      });
    },
    async getPropertyOwners() {
      await axiosBase.get("api/v1/propertyowner/").then((response) => {
        this.property_owners = response.data;
      });
    },
    async getAttributes() {
      const mapData = (item) => {
        return {
          value: item.id.toString(),
          key: item.value,
          text: item.value.concat(" - ", item.description),
          active: item.active,
        };
      };

      await axiosBase.get("api/v1/attributes/").then((response) => {
        const getItems = (type, defaultValue) => {
          let items = response.data
            .filter((x) => x.field_category === type)
            .map(mapData);
          if (defaultValue) {
            let item = items.find((x) => x.key === defaultValue);
            if (item) item.default = true;
          }
          return items;
        };
        this.attributes = response.data.map(mapData);

        this.fire_alarms.push(...getItems("fire_alarm"));
        this.burglar_alarms.push(...getItems("burglar_alarm"));
        this.categories_external.push(...getItems("category_external"));
        this.categories_internal.push(...getItems("category_internal"));
        this.facility_classes.push(...getItems("facility_class"));
        this.fire_protections.push(...getItems("fire_protection"));
        this.form_of_insurances.push(...getItems("form_of_insurance"));
        this.solar_cells.push(...getItems("solar_cells"));
        this.cultural_protection_types.push(
          ...getItems("cultural_protection_type", "NO")
        );
      });
    },
    async checkOrigin() {
      this.getLabels();
      this.clearOptions();
      await this.getMunicipalities();
      this.getPropertyOwners();
      this.getAttributes();
      this.bCreate = !this.$route.params.id;
      if (!this.bCreate) this.loadItem();
      else this.coord = { lat: 0, lon: 0 };
    },
    onReset(event) {
      console.log("onReset");
      event.preventDefault();
      this.form.food = null;
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style>
#create {
  margin-left: 15px;
}

@media (min-width: 768px) {
  #edit {
    padding-right: 5px;
  }

  #edit,
  .btn,
  .btn-primary {
    margin-left: 15px;
  }
}

@media (min-width: 1400px) {
  #edit {
    margin-left: 104px;
  }

  #create {
    margin-left: 104px;
    margin-top: 3rem;
  }

  .multii-collapse {
    margin-left: 104px;
  }
}
/*knapp*/

#godkann {
  margin-top: 3rem;
}
div.ol-unselectable ol-layers {
  margin-top: 22px;
}
div.ol-zoom ol-unselectable ol-control {
  margin-top: 22px;
}
.gul {
  padding-right: 20px;
  margin-left: 17%;
}
b#changelock.gul {
  margin-left: 102px;
}
select#intermediary.form-Mottagare {
  margin-top: 10px;
}

.grön {
  /*padding-left: 30%;*/
  padding-right: 20px;
}

.ändringTop {
  margin-top: -16px;
}

#NoChangeInput {
  font-size: 12px;
  margin-left: 29px;
}

#NoNewInput {
  font-size: 12px;
  margin-left: 3px;
}

.btn,
.btn-success {
  height: 38px;
  margin-top: 4px;
}

.outline-success {
  border: 3px solid black;
}

div#SkickaChangeFörmedlare.card.card-body {
  width: 30%;
}

div#SkickaNewFörmedlare.card.card-body {
  width: 30%;
}

div.form-check.form-Mottagare {
  padding: 0 !important;
  margin-left: 30px;
}

#hittaKoordinator {
  margin-top: 32px;
}

#Förmedlarhus {
  margin-bottom: 2px;
}

.card-body {
  width: 50%;
}

.table > :not(caption) > * > * {
  padding: 0.3rem 0.3rem 0.3rem !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  margin-right: 2px;
  margin-bottom: 4px;
}

.form-select {
  width: 100% !important;
  overflow: auto;
}

#skicka {
  margin-top: 20px;
}

.multii-collapse {
  overflow: auto;
}

.input-group-prepend {
  margin-bottom: 0.4rem !important;
}

.col {
  padding-left: 5px !important;
  padding-right: 5px !important;
  flex: 15% !important;
}

.btn-icon {
  height: 14px;
  margin: 0 auto;
  margin-top: -3px;
}

.createMap {
  height: 50% !important;
  margin: auto !important;
  margin-left: 10px !important;
}

.form-row > .form-row [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/*knapp till kommuner */
#edit {
  margin-top: 3rem;
  display: inline-flex;
  padding-left: 10px;
  padding-right: 10px;
}
.crateContainer {
  margin-top: 3rem !important;
}
.form-row {
  margin: 0 auto;
  display: flex;
}
.needs-validation {
  max-width: 1250px !important;
  margin: 3rem auto 0;
  margin-left: 97px;
}

div.form-row {
  margin-bottom: 2rem;
}
.Form-control {
  margin-top: 0 !important;
  margin-bottom: 15px;
  width: 100% !important;
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0 !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 0 solid #ced4da;
}

.input-group-text {
  background-color: #e9ecef;
  border: 0 solid #ced4da !important;
  border-radius: 0.25rem;
}

.card-edit {
  max-width: 35%;
  padding-right: 12px;
  margin-bottom: 40px;
  padding-top: 14px;
}
</style>

<style scoped>
.form-check {
  padding-left: 5px;
}

label {
  font-weight: 600;
  color: #0176ba;
}

.ol-viewport {
  margin-top: -25px;
}
</style>
