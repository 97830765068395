<template>
  <div class="box">
    <div class="boxOne">
      <br />

      <div class="row" id="'LoginForm">
        <legend class="col-md-6" id="LoggaIn">Logga in</legend>
        <div class="col-md-3"></div>
        <div class="col-md-6" id="LoggaBox">
          <form @submit.prevent="submitForm" id="login">
            <div class="mb-3">
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                placeholder="Email"
                v-model="user.email"
              />
            </div>

            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
              v-model="user.password"
            />

            <button
              type="submit"
              class="btn btn-primary"
              id="förstaSidan"
              data-target="#exampleModal"
              data-toggle="modal"
            >
              Logga in
            </button>
            <p>
              <router-link :to="{ name: 'ResetPassword' }"
                >Återställ lösenord</router-link
              >
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  name: "Login",
  data() {
    return {
      user: { email: "", password: "" },
      routeTo: this.$route.redirectedFrom
        ? this.$route.redirectedFrom.fullPath
        : "/Main",
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    async submitForm() {
      await this.$store.dispatch("login", this.user);
      if (this.$store.getters.isLoggedIn) {
        await this.$router.push({ path: this.routeTo });
      } else {
        this.toast.error("Wrong Username/Password");
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .box {
    margin-left: 450px;
    width: 50%;
    margin-top: 20px;
  }
}
@media (min-width: 1400px) {
  .box {
    margin-left: 40%;
    width: 35%;
    margin-top: 100px;
  }
}

button#förstaSidan.btn.btn-primary {
  margin-left: 2px !important;
}

#login {
  box-shadow: rgb(0 0 0 / 6%) 1px 1px 9px 1px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
}

#login {
  padding-left: 20px;
}

#LoggaBox {
  padding-left: 0;
}
</style>

<style scoped></style>
